<template>
  <div class="circle">
    <Search :searchOptions="options" @search="getSearch">
      <div class="btns">
        <el-button class="btn" type="primary" @click="modalShow"
          >新增</el-button
        >
      </div>
    </Search>
    <div class="circle_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button type="text" @click="look('edit', scope)">编辑</el-button
            ><el-button type="text" style="color: red" @click="del(scope)"
              >删除</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!--  -->
    <!-- 弹窗表单 -->
    <el-dialog
      title="便民生活圈"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="店铺名称" prop="shopName">
          <el-input
            v-model="ruleForm.shopName"
            placeholder="请输入店铺名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="生活圈层级" prop="shopLevel">
          <el-select
            v-model="ruleForm.shopLevel"
            placeholder="请选择生活圈层级"
          >
            <el-option label="5分钟生活圈" :value="0" />
            <el-option label="10分钟生活圈" :value="1" />
            <el-option label="15分钟生活圈" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="shopType">
          <el-select v-model="ruleForm.shopType" placeholder="请选择类型">
            <el-option label="商城" :value="0" />
            <el-option label="药店" :value="1" />
            <el-option label="超市" :value="2" />
            <el-option label="餐饮" :value="3" />
            <el-option label="学校" :value="4" />
            <el-option label="医院" :value="5" />
          </el-select>
        </el-form-item>
        <el-form-item label="位置" prop="shopAddress">
          <el-input v-model="ruleForm.shopAddress" placeholder="请输入位置" />
        </el-form-item>
        <el-form-item label="图片(1张)" prop="shopPicture">
          <el-upload
            action="#"
            ref="upload"
            :limit="1"
            list-type="picture-card"
            :on-remove="handleRemove"
            :http-request="uploadSectionFile"
            :file-list="fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="定位" prop="shopLocation">
          <div id="maps"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "店铺名称",
          prop: "shopName",
        },
        {
          type: "select",
          placeholder: "生活圈层级",
          prop: "shopLevel",
          options: [
            {
              label: "5分钟",
              value: 0,
            },
            {
              label: "10分钟",
              value: 1,
            },
            {
              label: "15分钟",
              value: 2,
            },
          ],
        },
        {
          type: "select",
          placeholder: "类型",
          prop: "shopType",
          options: [
            { label: "商城", value: "0" },
            { label: "药店", value: "1" },
            { label: "超市", value: "2" },
            { label: "餐饮", value: "3" },
            { label: "学校", value: "4" },
            { label: "医院", value: "5" },
          ],
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "店铺名称",
          prop: "shopName",
        },
        {
          label: "所属生活圈层级",
          prop: "shopLevel",
          enum: {
            0: "5分钟",
            1: "10分钟",
            2: "15分钟",
          },
        },
        {
          label: "类型",
          prop: "shopType",
          enum: {
            0: "商城",
            1: "药店",
            2: "超市",
            3: "餐饮",
            4: "学校",
            5: "医院",
          },
        },
        {
          label: "位置",
          prop: "shopAddress",
        },
        {
          type: "img",
          label: "店铺图片",
          prop: "shopPicture",
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
      ],
      ruleForm: {
        shopName: "",
        shopLevel: "",
        shopType: "",
        shopAddress: "",
        shopLocation: "",
        shopPicture: [],
      },
      rules: {
        shopName: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        shopLevel: [
          { required: true, message: "请选择生活圈层级", trigger: "change" },
        ],
        shopType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        shopAddress: [
          { required: true, message: "请输入位置", trigger: "change" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        shopLocation: [
          { required: true, message: "请标记定位", trigger: "blur" },
        ],
      },
      fileList: [],
      markerLayer: null,
      mapInstance: null,
      isDisabled: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    look(type, row) {
      this.mode = type;
      this.ruleForm = Object.assign(
        {},
        {
          ...row,
          shopPicture: row.shopPicture ? [row.shopPicture] : [],
        }
      );
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (row.shopPicture) {
          this.fileList = [
            {
              url: row.shopPicture,
            },
          ];
        }
        if (type == "look") {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
        this.initMap();
      });
    },
    del(row) {
      this.$confirm("此操作将删除该信息, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { flag } = await this.$del(
            `/xng/backed/convenientLifeCircle/delConvenientLifeCircle?id=${row.id}`
          );
          if (flag) {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSearch(val) {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        ...val,
      };
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/convenientLifeCircle/selectPageListByName",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows.map((item) => {
          return {
            ...item,
            url: item.shopPicture,
          };
        });
      }
    },
    modalShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initMap();
      });
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
          this.fileList = [];
        })
        .catch((_) => {});
    },
    // 图片上传
    async uploadSectionFile(params) {
      const formData = new FormData();
      console.log(formData);

      formData.append("files", params.file);
      const res = await this.$post("/clue/upLoad", formData);
      if (res.flag) {
        this.ruleForm.shopPicture.push(res.data[0]);
        this.fileList.push({ url: res.data[0] });
      }
    },
    handleRemove(file, fileList) {
      this.ruleForm.shopPicture.map((item, index) => {
        if (item == file.url) {
          this.ruleForm.shopPicture.splice(index, 1);
          this.fileList.splice(index, 1);
        }
      });
    },
    initMap() {
      let arr = [];
      if (this.ruleForm.id) {
        arr = this.ruleForm.shopLocation.split(",");
      }
      // console.log(arr);
      //定义地图中心点坐标
      let center = new window.TMap.LatLng(
        arr.length ? arr[0] : 30.639047,
        arr.length ? arr[1] : 104.096055
      );
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      let maps = new window.TMap.Map(document.getElementById("maps"), {
        center: center, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
      });
      if (this.ruleForm.id) {
        console.log(arr);
        this.markerLayer = new TMap.MultiMarker({
          id: "marker-layer", //图层id
          map: maps,
          styles: {
            marker: new TMap.MarkerStyle({
              width: 25,
              height: 35,
              anchor: { x: 16, y: 32 },
              src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png",
            }),
          },
          geometries: [
            {
              id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
              position: new window.TMap.LatLng(arr[0], arr[1]), //点标记坐标位置
            },
          ],
        });
      }
      if (!this.isDisabled) {
        maps.on("click", (evt) => {
          if (this.markerLayer?.id) {
            this.markerLayer.setMap(null);
          }
          this.markerLayer = new TMap.MultiMarker({
            id: "marker-layer", //图层id
            map: maps,
            styles: {
              marker: new TMap.MarkerStyle({
                width: 25,
                height: 35,
                anchor: { x: 16, y: 32 },
                src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png",
              }),
            },
            geometries: [
              {
                id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                position: new window.TMap.LatLng(
                  evt.latLng.lat,
                  evt.latLng.lng
                ), //点标记坐标位置
              },
            ],
          });
          this.ruleForm = {
            ...this.ruleForm,
            shopLocation: `${evt.latLng.lat},${evt.latLng.lng}`,
          };
        });
        this.mapInstance = maps;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          if (this.ruleForm.id) {
            const { flag } = await this.$post(
              "/xng/backed/convenientLifeCircle/updateConvenientLifeCircle",
              {
                ...this.ruleForm,
                shopPicture: this.ruleForm.shopPicture.length
                  ? this.ruleForm.shopPicture[0]
                  : "",
              }
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功",
                type: "success",
              });
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const { flag } = await this.$post(
              "/xng/backed/convenientLifeCircle/addConvenientLifeCircle",
              {
                ...this.ruleForm,
                shopPicture: this.ruleForm.shopPicture.length
                  ? this.ruleForm.shopPicture[0]
                  : "",
              }
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
            } else {
              this.$message.error("新增失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.circle {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .circle_content {
    width: 100%;
  }
}
</style>
